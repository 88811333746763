import APIBase from "./APIBase";

/**
 * @typedef AddressesService
 * @type {AddressesService}
 */
export class AddressesService extends APIBase {
	/**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Addresses", sessionStore);
	}

    async CreateAddress(address) {
        return this.Post(`CreateAddress`, address);
    }
	
    async UpdateAddress(address) {
        return this.Post(`UpdateAddress`, address);
    }

    async UpdateInstallationDate(addressId, installationDate) {
        return this.Post(`UpdateInstallationDate?addressId=${addressId}&installationDate=${installationDate}`);
    }

    async ImportAddresses(csvData) {
        return this.Post("ImportAddresses", csvData);
    }

    async GetAddresses(search, sortColumn, sortDirection, pageIndex, pageCount) {       
        return this.Get(`GetAddresses?search=${encodeURIComponent(search)}&sortColumn=${encodeURIComponent(sortColumn)}&sortDirection=${encodeURIComponent(sortDirection)}&pageIndex=${encodeURIComponent(pageIndex)}&pageCount=${encodeURIComponent(pageCount)}`);
    }

    async ExportAddresses(search, sortColumn, sortDirection) {
        return this.Get(`GetAddresses?search=${encodeURIComponent(search)}&sortColumn=${encodeURIComponent(sortColumn)}&sortDirection=${encodeURIComponent(sortDirection)}`);
    }

    async GetRadiusName(addressId) {
        return this.Get(`GetRadiusName?addressId=${encodeURIComponent(addressId)}`);
    }

    async GetRadiusStatus(username) {
        return this.Get(`GetRadiusStatus?username=${encodeURIComponent(username)}`);
    }

    async GetRouterStatus(username) {
        return this.Get(`GetRouterStatus?username=${encodeURIComponent(username)}`);
    }

    async MarkInstallationAsComplete(addressId, splitter, serialNumber, radiusUsername, w3w, installDate) {
        return this.Post(`MarkInstallationAsComplete?addressId=${encodeURIComponent(addressId)}&splitter=${encodeURIComponent(splitter)}&serialNumber=${encodeURIComponent(serialNumber)}&radiusUsername=${encodeURIComponent(radiusUsername)}&w3w=${encodeURIComponent(w3w)}&installDate=${encodeURIComponent(installDate)}`);
    }
}