import { Table, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useSessionStore } from "../../../../Stores/SessionStore";
import PageContainer from "../../../Core/PageContainer";
import Headerbar from "./Headerbar";
import AddressModal from "../Details/AddressModal";
import InstallCompleteModal from "../Details/InstallCompleteModal";
import BulkImportModal from "../Details/BulkImportModal";
import Panel from "../../../Core/Panel";
import ISODate from "../../../Core/ISODate";
import IndexActionsDropwdown from "../Details/IndexActionsDropdown";
import SortableTableHeader from "../../../Core/SortableTableHeader";
import Pagination from "../../../Core/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { AddressSortColumns, SortDirections} from "../../../../Utilities/Enums";
import AlertModal from "../../../Core/AlertModal";

export default function Addresses() {
	const store = useSessionStore();
	
	const [addresses, setAddresses] = useState(null);
	const [actionAddress, setActionAddress] = useState(null);
	const [showAddressModal, setShowAddressModal] = useState(false);
	const [showInstallComplete, setShowInstallComplete] = useState(false);
	const [showBulkImport, setShowBulkImport] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
	const [checkingStatus, setCheckingStatus] = useState(false);   

	const [filters, setFilters] = useState({
		PageIndex: 0,
		PageCount: 50,
		Total: 50,
		Search: "",
		SortColumn: AddressSortColumns.ERF,
		SortDirection: SortDirections.Ascending
	});

	useEffect(() => {
		getAddresses(filters);
	}, []);

	async function getAddresses(filters) {

		setAddresses(null);
		
		const { Search, SortColumn, SortDirection, PageIndex, PageCount } = filters;

		let result = await store.AddressesService.GetAddresses(Search, SortColumn, SortDirection, PageIndex, PageCount);
		
		if(result && result.Success) {			

			setAddresses(result.Data);
			
			setFilters({...filters, Total: result.Total});
		} else {
			setAddresses([]);
		}
	}

	async function handleBulkExport() {

		const result = await store.AddressesService.ExportAddresses(filters.Search, filters.SortColumn, filters.SortDirection);
		
		if (result && result.Success) {			
			delete result.Data[0].Splitter;
			const headers = Object.keys(result.Data[0]).join(",");
			const csv = headers + "\n" + result.Data.map(e => {
						delete e.Splitter;
						return Object.values(e).map(value => 
						(
							value ? value.toString().replace(/[\r\n]/g, "") : "")
						).join(",")
					}).join("\n");
			const blob = new Blob([csv], { type: "text/csv" });

			saveAs(blob, "Addresses.csv");
		} else {
			setAlertMessage("Export Failed");
			setShowAlert(true);
		}
	}

	function handleBulkImport() {
		setShowBulkImport(true);
	}

	function handleEditAddress(address) {
		setActionAddress(address);
		setShowAddressModal(true);
	}

	function handleAddressModalClosed(save = false) {
		setActionAddress(null);
		setShowAddressModal(false);

		if (save) getAddresses(filters);
	}

	async function handleRefreshStatus(username) {

		setCheckingStatus(true);

		const [RadiusStatus, RouterStatus] = await Promise.all([
				await store.AddressesService.GetRadiusStatus(username),
				await store.AddressesService.GetRouterStatus(username)
			]);

		setCheckingStatus(false);

		let newAddresses = addresses.map(address => {
			if (address.RadiusUsername === username) {
				address.RadiusStatus = RadiusStatus.Data;
				address.RouterStatus = RouterStatus.Data;
			}

			return address;
		});

		setAddresses(newAddresses);
	}

	function handleInstallComplete(address) {
		setActionAddress(address);
		setShowInstallComplete(true);
	}

	function handleInstallCompleteModalClosed(save = false) {
		setActionAddress(null);
		setShowInstallComplete(false);
		
		if (save) getAddresses(filters);
	}

	function handleSort(sortColumn) {
		let newFilters = { ...filters, SortColumn: sortColumn, SortDirection: filters.SortDirection === SortDirections.Ascending ? SortDirections.Descending : SortDirections.Ascending };

		setFilters(newFilters);
		getAddresses(newFilters);
	}

	function handleSearch(search) {
		let newFilters = { ...filters, PageIndex: 0, Search: search };

		setFilters(newFilters);
		getAddresses(newFilters);
	}

	function handlePageChanged(pageIndex, pageCount) {
		let newFilters = { ...filters, PageIndex: pageIndex, PageCount: pageCount };

		setFilters(newFilters);
		getAddresses(newFilters);
	}

	return 	<PageContainer title="Address Management">
				<Panel  title="Address Management">
					<Headerbar onAddClicked={ () => {setActionAddress(null); setShowAddressModal(true)}} handleBulkExport={() => {handleBulkExport()}} handleBulkImport={() => {handleBulkImport()}} handleSearch={handleSearch}></Headerbar>
					<div>						
						<Table responsive striped>
							<thead>
								<tr>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.Township)} } sortBy={AddressSortColumns.Township} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>Township</SortableTableHeader>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.ERF)} } sortBy={AddressSortColumns.ERF} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>ERF</SortableTableHeader>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.StreetAddress)} } sortBy={AddressSortColumns.StreetAddress} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>Street Address</SortableTableHeader>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.MDUName)} } sortBy={AddressSortColumns.MDUName} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>MDU Name</SortableTableHeader>
									<th className="table-admin-header">MDU Block</th>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.MDUUnitNumber)} } sortBy={AddressSortColumns.MDUUnitNumber} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>MDU Unit No.</SortableTableHeader>
									<th className="table-admin-header">W3W</th>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.PropertyType)} } sortBy={AddressSortColumns.PropertyType} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>Property Type</SortableTableHeader>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.POP)} } sortBy={AddressSortColumns.POP} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>POP</SortableTableHeader>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.InstallDate)} } sortBy={AddressSortColumns.InstallDate} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>Install Date</SortableTableHeader>
									<SortableTableHeader onClick={ () => {handleSort(AddressSortColumns.RadiusUsername)} } sortBy={AddressSortColumns.RadiusUsername} sortDirection={filters.SortDirection} sortedBy={filters.SortColumn}>PPPoE Username</SortableTableHeader>
									<th className="table-admin-header">PPPoE password</th>
									<th className="table-admin-header text-center">Active Package</th>
									<th className="table-admin-header text-center">ONT Status</th>
									<th className="table-admin-header text-center">PPPoE Status</th>
									<th className="text-center table-admin-header" size="sm">Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									addresses && addresses.map((address, index) => {
										return 	<tr key={index} className="" style={{height: "5px"}}>
													<td className="d-none d-sm-table-cell td-admin">{address.Township}</td>
													<td className="d-none d-sm-table-cell td-admin">{address.ERF}</td>
													<td className="d-none d-lg-table-cell td-admin">{address.StreetAddress}</td>
													<td className="d-none d-sm-table-cell td-admin">{address.MDUName}</td>
													<td className="d-none d-sm-table-cell td-admin">{address.MDUBlock}</td>
													<td className="d-none d-sm-table-cell td-admin">{address.MDUUnitNumber}</td>
													<td className="d-none d-sm-table-cell td-admin">
														<a href={`https://what3words.com/${address.W3W}`} target="_blank" rel="noopener noreferrer">
															{address.W3W}
														</a>
													</td>
													<td className="d-none d-sm-table-cell td-admin">{address.PropertyType}</td>
													<td className="d-none d-sm-table-cell td-admin">{address.POP}</td>
													<td className="d-none d-sm-table-cell td-admin">{address.InstallDate && (address.InstallDate.substring(0, 4) === "0001" ? "" : <ISODate date={address.InstallDate}/>)}</td>
													<td className="d-none d-sm-table-cell td-admin">{address.RadiusUsername}</td>
													<td className="d-none d-sm-table-cell td-admin">{address.RadiusPassword}</td>
													<OverlayTrigger overlay={address.HasActivePackage ? <Tooltip>{`${address.ActivePackageName ? address.ActivePackageName : ""}`}{address.ActivePackageName && <br/>}{`(${address.ExpirationDate})`}</Tooltip> : <></>}>
														<td className="d-none d-sm-table-cell td-admin" align="center"  id={address.Id}>
															{checkingStatus ? <Spinner animation="grow" size="sm" variant="warning"></Spinner> : <FontAwesomeIcon className="" color={address.HasActivePackage ? "green" : "red"} icon={ faDotCircle }></FontAwesomeIcon>}
														</td>
													</OverlayTrigger>														
													<td className="d-none d-sm-table-cell td-admin" align="center">{checkingStatus ? <Spinner animation="grow" size="sm" variant="warning"></Spinner> : <FontAwesomeIcon className="" color={address.RouterStatus ? "green" : "red"} icon={ faDotCircle } ></FontAwesomeIcon>}</td>
													<td className="d-none d-sm-table-cell td-admin" align="center">{checkingStatus ? <Spinner animation="grow" size="sm" variant="warning"></Spinner> : <FontAwesomeIcon className="" color={address.RadiusStatus ? "green" : "red"} icon={ faDotCircle } ></FontAwesomeIcon>}</td>
													<td align="center" className="py-1">{<IndexActionsDropwdown handleRefreshStatus={() => {handleRefreshStatus(address.RadiusUsername)}} installComplete={address.InstallComplete} addressId={address.Id} handleEditAddress={() => handleEditAddress(address)} handleInstallComplete={(() => handleInstallComplete(address))} ></IndexActionsDropwdown>}</td>
												</tr>
									})
								}
							</tbody>
						</Table>
						{
							addresses === null &&
							<div className="text-center">
								<Spinner animation="border"></Spinner>
							</div>
						}
						<Pagination filter={ filters } 
							totalResults={ filters ? filters.Total : 0 }
							onPageChanged={ handlePageChanged }>
						</Pagination>
					</div>	
				</Panel>
				<AddressModal show={showAddressModal} onClose={handleAddressModalClosed} address={actionAddress ? actionAddress : null}></AddressModal>
				<InstallCompleteModal show={showInstallComplete} onClose={handleInstallCompleteModalClosed} address={actionAddress}></InstallCompleteModal>
				<BulkImportModal show={showBulkImport} onClose={() => {setShowBulkImport(false); getAddresses(filters);}}></BulkImportModal>
				<AlertModal				
					show={showAlert}
					onClose={() => {
						setShowAlert(false);
						setAlertMessage("");
						setSuccessMessage("");
					}}
					message={alertMessage ? alertMessage : successMessage}
					success={successMessage}						
				/>
			</PageContainer>
}